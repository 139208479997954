import PhoneNumber from 'awesome-phonenumber';
import {AttachmentableEntity, DocumentBase, MerchantView, SnoozeNotificationTime} from '.';
import {
  AgentStatus,
  getAgentStatus,
  getSearchTokens,
  GroupRole,
  SearchableTokens,
} from '../constants';
import {UniversalTimestamp} from './compat';
import {UserMFASettings} from './Multifactor';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export interface AgentGroup {
  id: string;
  name: string;
  logoUrl: string;
}

export interface AgentOffice {
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface AgentPermissions {
  residuals?: boolean;
}
export interface AdminContact {
  name: string;
  email: string;
}

export type Agent = DocumentBase &
  SnoozeNotificationTime &
  AttachmentableEntity & {
    group: AgentGroup;
    role: GroupRole;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    photoUrl?: string;
    dateOfBirth: UniversalTimestamp;
    office: AgentOffice;
    adminContact: AdminContact;
    timeZoneId: string;
    createdAt: UniversalTimestamp;
    isActive: boolean;
    merchantUids?: string[];
    deleted?: boolean | null;
    isDisabled?: boolean | null;
    masterUid?: string; // if agent is master this will be null
    subAgentUids?: string[]; // this would only include subagents if an agent is a master
    agentCode?: string;
    subAgentCode?: string;
    isGroupOwner?: boolean;
    permissions?: AgentPermissions;
    lastLoginAt?: UniversalTimestamp;
    masterUidSetBy?: string;
    mfa?: UserMFASettings;
    siteJumpAgents?: {id: string; fullName: string; groupName: string}[];
  };

export type AgentView = ViewBase<Agent> & {agentStatus: AgentStatus};
export type SearchableAgentView = AgentView & SearchableTokens;

export const selectAgentView: ViewSelector<AgentView, Agent> = snapshot => {
  const data = snapshot.data();

  const agent: AgentView = {
    ...defaultViewSelector(snapshot),
    phone: data.phone ? new PhoneNumber(data.phone, 'US').getNumber('national') : data.phone,
    agentStatus: getAgentStatus(data),
  };

  return agent;
};

export const selectSearchableAgentView: ViewSelector<SearchableAgentView, Agent> = snap => {
  const rawAgent = snap.data();
  const fieldValues = [
    rawAgent.firstName,
    rawAgent.lastName,
    rawAgent.phone,
    rawAgent.email,
    rawAgent.role,
    rawAgent.group.name,
    rawAgent.isActive ? 'active' : 'disabled',
  ];
  const searchTokens = getSearchTokens(fieldValues);
  const agent: SearchableAgentView = {
    ...selectAgentView(snap),
    searchTokens,
  };
  return agent;
};

export const isAgentProfile = (profile: AgentView | MerchantView): profile is AgentView => {
  return (
    (profile as AgentView).role !== undefined &&
    Object.values(GroupRole).includes((profile as AgentView).role)
  );
};
