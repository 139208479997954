import * as yup from 'yup';
import {emptyStringToNull, getApiToBoolean} from '../../../helpers';
import {ApplicationType} from '../../ApplicationType';
import {DiscountDuration} from '../../DiscountDuration';
import {PCIFee} from '../../PCIFee';
import {ProcessingTypes} from '../../ProcessingTypes';
import {ShippingType} from '../../ShippingType';
import {StatementType} from '../../StatementType';
import {StateHash} from '../../USStates';
import {getCustomErrorMessage, getNumberTypeError, oneOfAllowEmpty} from '../Common';

export const ApplicationTypeAvailableOptions = [
  ApplicationType.flatRate,
  ApplicationType.cashDiscount,
  ApplicationType.interchange,
  ApplicationType.err,
  ApplicationType.tiered,
  ApplicationType.cnpVCp,
  ApplicationType.surcharge,
  ApplicationType.dualPricing,
];

export const isPropsRequired = (
  pageField: PageFields,
  applicationType: ApplicationType,
  processingType?: ProcessingTypes
): boolean => {
  const pageFields = pageFieldsConst.find((e: {key: PageFields}) => e.key === pageField);
  if (!pageFields) {
    return true;
  }
  const isRequiredForApplicationType =
    pageFields.requiredForAll ||
    pageFields.requiredExactForApplicationType.includes(applicationType);

  if (!isRequiredForApplicationType || !pageFields.requiredExactForProcessingType) {
    // not required based on application type or there is no need to
    // verify by processingType. Return result based on only requiredExactForApplicationType
    return isRequiredForApplicationType;
  }

  if (!processingType) {
    return true;
  }
  return pageFields.requiredExactForProcessingType.includes(processingType);
};

export enum PageFields {
  qualifiedDiscountRateVisaMastercardDiscover,
  qualifiedTransactionFeeVisaMastercardDiscover,
  qualifiedDiscountRateAmex,
  qualifiedTransactionFeeAmex,
  qualifiedDebitPINRate,
  qualifiedDebitPINFee,
  qualifiedDebitNonPINRate,
  qualifiedDebitNonPINFee,
  midQualifiedDiscountRateVisaMastercardDiscover,
  midQualifiedTransactionFeeVisaMastercardDiscover,
  midQualifiedDiscountRateAmex,
  midQualifiedTransactionFeeAmex,
  nonQualifiedDiscountRateVisaMastercardDiscover,
  nonQualifiedTransactionFeeVisaMastercardDiscover,
  nonQualifiedDiscountRateAmex,
  nonQualifiedTransactionFeeAmex,
  statementType,
}

type PageFieldConfig = {
  key: PageFields;
  requiredForAll?: boolean;
  requiredExactForApplicationType: ApplicationType[];
  requiredExactForProcessingType?: ProcessingTypes[];
};

const pageFieldsConst: PageFieldConfig[] = [
  {
    key: PageFields.qualifiedDiscountRateAmex,
    requiredForAll: false,
    requiredExactForApplicationType: [
      ApplicationType.flatRate,
      ApplicationType.tiered,
      ApplicationType.interchange,
      ApplicationType.err,
      ApplicationType.cnpVCp,
    ],
  },
  {
    key: PageFields.qualifiedDiscountRateVisaMastercardDiscover,
    requiredForAll: true,
    requiredExactForApplicationType: [],
  },

  {
    key: PageFields.nonQualifiedDiscountRateAmex,
    requiredForAll: false,
    requiredExactForApplicationType: [ApplicationType.tiered, ApplicationType.cnpVCp],
  },
  {
    key: PageFields.nonQualifiedDiscountRateVisaMastercardDiscover,
    requiredForAll: false,
    requiredExactForApplicationType: [ApplicationType.tiered, ApplicationType.cnpVCp],
  },

  {
    key: PageFields.midQualifiedDiscountRateAmex,
    requiredForAll: false,
    requiredExactForApplicationType: [ApplicationType.tiered],
  },
  {
    key: PageFields.midQualifiedDiscountRateVisaMastercardDiscover,
    requiredForAll: false,
    requiredExactForApplicationType: [ApplicationType.tiered],
  },

  {
    key: PageFields.qualifiedTransactionFeeAmex,
    requiredForAll: false,
    requiredExactForApplicationType: [
      ApplicationType.flatRate,
      ApplicationType.tiered,
      ApplicationType.interchange,
      ApplicationType.err,
      ApplicationType.cnpVCp,
    ],
  },
  {
    key: PageFields.qualifiedTransactionFeeVisaMastercardDiscover,
    requiredForAll: true,
    requiredExactForApplicationType: [],
  },

  {
    key: PageFields.midQualifiedTransactionFeeAmex,
    requiredForAll: false,
    requiredExactForApplicationType: [ApplicationType.tiered],
  },
  {
    key: PageFields.midQualifiedTransactionFeeVisaMastercardDiscover,
    requiredForAll: false,
    requiredExactForApplicationType: [ApplicationType.tiered],
  },

  {
    key: PageFields.nonQualifiedTransactionFeeAmex,
    requiredForAll: false,
    requiredExactForApplicationType: [],
  },
  {
    key: PageFields.nonQualifiedTransactionFeeVisaMastercardDiscover,
    requiredForAll: false,
    requiredExactForApplicationType: [],
  },
  {
    key: PageFields.qualifiedDebitPINRate,
    requiredExactForApplicationType: [
      ApplicationType.cnpVCp,
      ApplicationType.err,
      ApplicationType.flatRate,
      ApplicationType.interchange,
      ApplicationType.tiered,
      ApplicationType.surcharge,
      ApplicationType.dualPricing,
    ],
    requiredExactForProcessingType: [ProcessingTypes.cardPresent],
  },
  {
    key: PageFields.qualifiedDebitPINFee,
    requiredExactForApplicationType: [
      ApplicationType.cnpVCp,
      ApplicationType.err,
      ApplicationType.flatRate,
      ApplicationType.interchange,
      ApplicationType.tiered,
      ApplicationType.surcharge,
      ApplicationType.dualPricing,
    ],
    requiredExactForProcessingType: [ProcessingTypes.cardPresent],
  },
  {
    key: PageFields.qualifiedDebitNonPINRate,
    requiredExactForApplicationType: [
      ApplicationType.err,
      ApplicationType.flatRate,
      ApplicationType.interchange,
      ApplicationType.tiered,
    ],
  },
  {
    key: PageFields.qualifiedDebitNonPINFee,
    requiredExactForApplicationType: [
      ApplicationType.err,
      ApplicationType.flatRate,
      ApplicationType.interchange,
      ApplicationType.tiered,
    ],
  },
  {
    key: PageFields.statementType,
    requiredExactForApplicationType: [ApplicationType.interchange],
  },
];

const whenShowDebitRatesSchema = (
  useCustomErrorMessage: boolean,
  label: 'Rate' | 'Fee',
  processingType?: ProcessingTypes
) =>
  yup
    .number()
    .when('applicationType', (applicationType: ApplicationType, schema: yup.NumberSchema) => {
      const isCashDiscount = applicationType === ApplicationType.cashDiscount;
      const isCnpVCp = applicationType === ApplicationType.cnpVCp;
      const showDebitRates =
        !isCashDiscount && (!isCnpVCp || processingType === ProcessingTypes.cardPresent);
      if (showDebitRates) {
        let fieldSchema = schema.min(0, 'Min value is 0');

        const schemaValidation = fieldSchema
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Field is required'))
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Please fill this field'));

        if (useCustomErrorMessage) {
          return schemaValidation.label(label);
        }
        return schemaValidation;
      }
      return schema.transform(emptyStringToNull).nullable();
    });

const cashDiscountPricingIsCappedAt = (cap: number, symbol: string) =>
  `Cash Discount Pricing is Capped at ${cap}${symbol}`;

export const GREATER_THAN_ZERO = 'Must be greater than 0';

const whenAppTypeSchema = (
  useCustomErrorMessage: boolean,
  pageField: PageFields,
  label: 'Rate' | 'Fee',
  processingType?: ProcessingTypes,
  cap?: number
) =>
  yup
    .number()
    .when('applicationType', (applicationType: ApplicationType, schema: yup.NumberSchema) => {
      if (isPropsRequired(pageField, applicationType, processingType)) {
        let fieldSchema = schema.min(
          0,
          getCustomErrorMessage(useCustomErrorMessage, 'Min value is 0')
        );

        if (
          pageField === PageFields.qualifiedDiscountRateVisaMastercardDiscover &&
          typeof cap !== 'undefined'
        ) {
          fieldSchema = fieldSchema.max(
            cap,
            getCustomErrorMessage(
              useCustomErrorMessage,
              cashDiscountPricingIsCappedAt(cap, label === 'Fee' ? '$' : '%')
            )
          );
        }

        const schemaValidation = fieldSchema
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Field is required'))
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Please fill this field'));
        if (useCustomErrorMessage) {
          return schemaValidation.label(label);
        }
        return schemaValidation;
      }
      return schema.transform(emptyStringToNull).nullable();
    });

const getWhenAppTypeSchema = (
  useCustomErrorMessage: boolean,
  processingType: ProcessingTypes,
  cashDiscountCap?: number
) => {
  return {
    applicationType: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Application Type is required'))
      .oneOf(Object.values(ApplicationTypeAvailableOptions)),
    discountDuration: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Discount Duration is required'))
      .oneOf(Object.values(DiscountDuration)),
    qualifiedDiscountRateVisaMastercardDiscover: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedDiscountRateVisaMastercardDiscover,
      'Rate',
      undefined,
      cashDiscountCap
    ),
    midQualifiedDiscountRateVisaMastercardDiscover: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.midQualifiedDiscountRateVisaMastercardDiscover,
      'Rate'
    ),
    nonQualifiedDiscountRateVisaMastercardDiscover: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.nonQualifiedDiscountRateVisaMastercardDiscover,
      'Rate'
    ),
    qualifiedTransactionFeeVisaMastercardDiscover: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedTransactionFeeVisaMastercardDiscover,
      'Fee'
    ),
    midQualifiedTransactionFeeVisaMastercardDiscover: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.midQualifiedTransactionFeeVisaMastercardDiscover,
      'Fee'
    ),
    nonQualifiedTransactionFeeVisaMastercardDiscover: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.nonQualifiedTransactionFeeVisaMastercardDiscover,
      'Fee'
    ),
    qualifiedDiscountRateAmex: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedDiscountRateAmex,
      'Rate'
    ),
    midQualifiedDiscountRateAmex: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.midQualifiedDiscountRateAmex,
      'Rate'
    ),
    nonQualifiedDiscountRateAmex: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.nonQualifiedDiscountRateAmex,
      'Rate'
    ),
    qualifiedTransactionFeeAmex: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedTransactionFeeAmex,
      'Fee'
    ),
    midQualifiedTransactionFeeAmex: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.midQualifiedTransactionFeeAmex,
      'Rate'
    ),
    nonQualifiedTransactionFeeAmex: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.nonQualifiedTransactionFeeAmex,
      'Fee'
    ),
    qualifiedDebitPINRate: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedDebitPINRate,
      'Rate',
      processingType
    ),
    ebtTransactionFee: whenShowDebitRatesSchema(useCustomErrorMessage, 'Fee', processingType),
    ebtDiscountRate: whenShowDebitRatesSchema(useCustomErrorMessage, 'Rate', processingType),
    qualifiedDebitPINFee: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedDebitPINFee,
      'Fee',
      processingType
    ),
    qualifiedDebitNonPINRate: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedDebitNonPINRate,
      'Rate'
    ),
    qualifiedDebitNonPINFee: whenAppTypeSchema(
      useCustomErrorMessage,
      PageFields.qualifiedDebitNonPINFee,
      'Fee'
    ),
    otherVolumeRate: (() => {
      let schema = yup
        .number()
        .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO))
        .typeError(getNumberTypeError(useCustomErrorMessage, 'Rate is required'));

      if (cashDiscountCap) {
        schema = schema.max(cashDiscountCap, cashDiscountPricingIsCappedAt(cashDiscountCap, '%'));
      }

      return schema;
    })(),
    otherItemFee: yup
      .number()
      .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO))
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Rate is required')),
    accountOnFileFee: yup
      .number()
      .min(0)
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Rate is required'))
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Rate is required')),
    chargebackFee: yup
      .number()
      .min(0)
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Rate is required'))
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Rate is required')),
    retrievalFee: yup
      .number()
      .min(0)
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Rate is required'))
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Rate is required')),
    batchFee: yup.number().min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    monthlyMinimumFee: yup
      .number()
      .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    debitAccessFee: yup
      .number()
      .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    additionalServicesFee: yup
      .number()
      .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    earlyDeconversionFee: yup
      .number()
      .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    pciFee: yup.string().test(oneOfAllowEmpty(Object.values(PCIFee))),
    pciFeeValue: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('pciFee', (pciFee: PCIFee, schema: any) => {
        if (pciFee && pciFee !== PCIFee.waived) {
          return schema
            .min(
              0,
              getCustomErrorMessage(useCustomErrorMessage, 'PCI Fee must be a number 0 or greater')
            )
            .required(getCustomErrorMessage(useCustomErrorMessage, 'PCI Fee Value is required'))
            .typeError(getNumberTypeError(useCustomErrorMessage, 'PCI Fee Value is required'));
        }
      }),
    annualFee: yup.number().min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    regulatoryFee: yup
      .number()
      .min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    avsFee: yup.number().min(0, getCustomErrorMessage(useCustomErrorMessage, GREATER_THAN_ZERO)),
    mailStatement: yup
      .boolean()
      .transform(getApiToBoolean(useCustomErrorMessage))
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Mail Statement is required')),
    statementType: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('applicationType', (applicationType: ApplicationType, schema: any) => {
        if (applicationType === ApplicationType.interchange) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Statement Type is required')
          );
        }
      })
      .test(oneOfAllowEmpty(Object.values(StatementType))),
  };
};

export const getProgramEquipmentValidationShippingCommonObject = (
  useCustomErrorMessage: boolean
) => {
  return {
    select: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .test(oneOfAllowEmpty(Object.values(ShippingType))),
    attention: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('other', {
        is: true,
        then: yup
          .string()
          .nullable(false)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Attention is required')),
      }),
    other: yup.boolean().default(false).transform(emptyStringToNull).nullable(),
    address1: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('other', {
        is: true,
        then: yup
          .string()
          .nullable(false)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Address is required')),
      }),
    city: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('other', {
        is: true,
        then: yup
          .string()
          .nullable(false)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'City is required')),
      }),
    state: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('other', {
        is: true,
        then: yup
          .string()
          .nullable(false)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'State is required')),
      })
      .test(oneOfAllowEmpty(Object.keys(StateHash))),
    zip: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('other', {
        is: true,
        then: yup
          .string()
          .nullable(false)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Zip Code is required'))
          .min(5, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid zip code')),
      }),
  };
};

const stringNoExtraSpaces = (value: string | undefined) => {
  if (value) {
    return new RegExp(/^\S.*\S$/).test(value);
  }
  return true;
};

const getProgramEquipmentValidationCommonObject = (
  useCustomErrorMessage: boolean,
  shippingValidationObject: any,
  cashDiscountCap?: number
) => {
  return {
    doingBusinessAs: yup
      .string()
      .test(
        'no-extra-spaces-allowed',
        'Please remove leading and trailing spaces' + (useCustomErrorMessage ? '' : ' in ${path}'),
        value => stringNoExtraSpaces(value)
      )
      .max(25, "DBA name can't be longer than 25 characters"),
    businessType: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Specific Business Type is required'))
      .when('$businessTypes', (businessTypes, schema) => {
        if (businessTypes) {
          return schema.oneOf(Object.values(businessTypes));
        }
        return;
      }),
    processingType: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'You must select an option'))
      .oneOf(Object.values(ProcessingTypes)),
    rateSet: yup
      .object()
      .required()
      .when('processingType', (processingType: ProcessingTypes) => {
        return yup
          .object()
          .shape(getWhenAppTypeSchema(useCustomErrorMessage, processingType, cashDiscountCap));
      }),
    shipping: yup.object(shippingValidationObject),
  };
};

export const getWebProgramEquipmentValidationObject = (cashDiscountCap?: number) => {
  const programEquipmentValidationShipping = {
    ...getProgramEquipmentValidationShippingCommonObject(true),
    select: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('equipment.isGateway', {
        is: false,
        then: yup.string().nullable(false).required('Select is required'),
      }),
  };
  return getProgramEquipmentValidationCommonObject(
    true,
    programEquipmentValidationShipping,
    cashDiscountCap
  );
};

export const getApiProgramEquipmentValidationObject = () => {
  const programEquipmentValidationShipping =
    getProgramEquipmentValidationShippingCommonObject(false);
  return getProgramEquipmentValidationCommonObject(false, programEquipmentValidationShipping);
};
