import {
  CommandNames,
  DownloadPdfPayload,
  DownloadPdfResult,
  EncryptFieldResult,
  Firebase,
  GetTransferAuthTokenPayload,
  GetTransferAuthTokenResult,
} from '@ozark/common';
import {useCallback} from 'react';

const createCallableWrapper =
  <TPayload, TResult>(command: CommandNames) =>
  async (payload?: TPayload): Promise<TResult> => {
    const callFunction = Firebase.functions.httpsCallable('dispatchCallable');
    const result: TResult = await (async () => {
      try {
        const {data} = await callFunction({command, payload});
        return data;
      } catch (err: any) {
        return {
          status: 'error',
          message: err.message,
        };
      }
    })();
    return result;
  };

export const useCallable = () => {
  const startApplication = useCallback(
    async ({
      email,
      group,
      registration,
      tags,
      sourceApplicationId,
      externalLeadId,
      agentUid,
      startApplicationLink,
    }: {
      email: string;
      group: {id: string; logoUrl: string; name: string};
      registration: {
        firstName: string;
        lastName: string;
        businessPhone: string;
        timeZoneId: string;
      } | null;
      tags?: {[key: string]: string};
      sourceApplicationId?: string;
      externalLeadId?: string;
      agentUid?: string;
      startApplicationLink?: string;
    }) => {
      const {data} = await Firebase.functions.httpsCallable('startApplication')({
        email,
        group,
        registration,
        tags,
        sourceApplicationId,
        externalLeadId,
        agentUid,
        startApplicationLink,
      });
      return data;
    },
    []
  );

  const completeApplication = useCallback(
    async (id: string, signature: {fullPath: string; downloadUrl: string} | null) => {
      const {data} = await Firebase.functions.httpsCallable('completeApplication')({id, signature});
      return data;
    },
    []
  );

  const encryptField = useCallback(async (plainText: string) => {
    const {data} = await Firebase.functions.httpsCallable('encryptField')({plainText});
    if (!data) return null;
    return data as EncryptFieldResult;
  }, []);

  const dispatchable = {
    [CommandNames.getTransferAuthToken]: createCallableWrapper<
      GetTransferAuthTokenPayload,
      GetTransferAuthTokenResult
    >(CommandNames.getTransferAuthToken),
    [CommandNames.downloadPdf]: createCallableWrapper<DownloadPdfPayload, DownloadPdfResult>(
      CommandNames.downloadPdf
    ),
  };

  const getPersonaIdConfig = useCallback(async () => {
    return await Firebase.functions.httpsCallable('getPersonaIdConfig')();
  }, []);

  return {
    startApplication,
    completeApplication,
    encryptField,
    getPersonaIdConfig,
    ...dispatchable,
  };
};
