export enum ApplicationType {
  tiered = 'Tiered',
  flatRate = 'Flat Rate',
  cashDiscount = 'Cash Discount',
  interchange = 'Interchange',
  err = 'ERR',
  cnpVCp = 'CNP v CP',
  surcharge = 'Surcharge',
  dualPricing = 'Dual Pricing',
}
