import {
  ApplicationEquipment,
  ApplicationType,
  EquipmentFileType,
  isOtherNotListedEquipment,
  isVarSheetOtherEquipment,
  PCIFee,
  PCIFeeTypes,
  PlacementsValues,
  PlacementType,
  ProcessingTypes,
  RateType,
  ShippingType,
  useRateProgramsByGroup,
} from '@ozark/common';
import {useApplicationShippingAddresses} from '@ozark/common/components/Application/SectionShipping/useApplicationShippingAddresses';
import {PRICING_VISA_MASTER} from '@ozark/common/constants/strings';
import {getAdjustedDiscountRateBilledToMerchant} from '@ozark/common/helpers';
import {get} from 'lodash';
import {useEquipment} from '../../../../firebase/hooks/useEquipment';
import {PageAnswers, QuestionAnswer, SectionAnswers} from '../types';
import {useSummaryCommon} from './useSummaryCommon';

export const useSelectYourProgramStepAnswers = () => {
  const {application, emptyAnswers, getAvailableQuestionAnswer, getQuestionAnswer, getYesNo, app} =
    useSummaryCommon();
  const {shippingAddresses} = useApplicationShippingAddresses(application.data);
  const {ratePrograms, getRateSetByProcessingType} = useRateProgramsByGroup(
    application.data?.group?.id
  );
  const {equipment} = useEquipment(application.data?.processingType);

  const isCashDiscountProg = app?.rateSet?.applicationType === ApplicationType.cashDiscount;
  const isSurchargeDualPricing =
    app?.rateSet?.applicationType === ApplicationType.surcharge ||
    app?.rateSet?.applicationType === ApplicationType.dualPricing;
  const isCnpProg = app?.rateSet?.applicationType === ApplicationType.cnpVCp;
  const isErrProg = app?.rateSet?.applicationType === ApplicationType.err;
  const isDebit =
    app?.rateSet?.applicationType &&
    ![
      ApplicationType.interchange,
      ApplicationType.err,
      ApplicationType.flatRate,
      ApplicationType.tiered,
    ].includes(app?.rateSet?.applicationType);

  const getEquipmentShipping = (isGateway: boolean | undefined): SectionAnswers => {
    let shippingSelectValue = shippingAddresses.find(a => a.key === app.shipping?.select)?.value;
    const isShippingOther = app.shipping?.select === ShippingType.other;
    if (isShippingOther) {
      shippingSelectValue = 'I will be using a different address and will enter it now.';
    }

    const isLocalPickup = app.shipping?.select === ShippingType.localPickup;
    if (isLocalPickup) {
      shippingSelectValue = 'I will coordinate a local pickup.';
    }

    return {
      isAvailable: isGateway === false,
      header: 'Shipping',
      questionAnswers: [
        getAvailableQuestionAnswer('Shipping Speed', app.equipment?.shippingSpeed ?? 'N/A'),
        getAvailableQuestionAnswer(
          'Where would you like to have this equipment shipped?',
          shippingSelectValue
        ),
        getQuestionAnswer('Attention', app.shipping?.attention, isShippingOther),
        getQuestionAnswer('Address Line 1', app.shipping?.address1, isShippingOther),
        getQuestionAnswer('Address Line 2', app.shipping?.address2, isShippingOther),
        getQuestionAnswer('City', app.shipping?.city, isShippingOther),
        getQuestionAnswer('State', app.shipping?.state, isShippingOther),
        getQuestionAnswer('Zip Code', app.shipping?.zip, isShippingOther),
      ],
    } as SectionAnswers;
  };

  const getSelectYourProgramStepAnswers = (): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    const getRateProgramsAsArray = () =>
      !ratePrograms.promised && ratePrograms.data ? ratePrograms.data : [];
    const getRateSet = (programName: string) =>
      getRateSetByProcessingType(ratePrograms, application.data?.processingType, programName)!;

    const program = getRateProgramsAsArray().find(x => x.id === app.programId);
    const programName = program?.name;
    let featuredInfo: string | undefined;
    if (!!programName) {
      const rateSet = getRateSet(programName);
      featuredInfo = `${rateSet.featuredText} ${rateSet.featuredCaption}`;
    }

    const equipmentInfo = equipment.data?.find(x => x.id === app.equipmentId);
    let equipmentName = equipmentInfo?.name;

    let equipmentPrice: string | undefined;
    let equipmentPlacementType: string | undefined;
    let equipmentSetupFee: RateType | undefined;
    let equipmentMonthlyFee: RateType | undefined;
    let equipmentTransFee: RateType | undefined;
    let equipmentBillingMethod: string | undefined;
    let equipmentNameQuestion: string = 'Name';

    const isGateway = app.equipment?.isGateway;
    const softwareName = app.equipment?.softwareName;
    let hideSoftwareName = true;
    const isVarSheetOther = isVarSheetOtherEquipment(equipmentName);
    const isOtherNotListed = isOtherNotListedEquipment(equipmentName);
    if (
      app.processingType !== ProcessingTypes.eCommerce &&
      !Boolean(equipmentInfo?.isOther) &&
      (equipmentInfo?.price || equipmentInfo?.price === 0)
    ) {
      equipmentPrice = `$${equipmentInfo?.price?.toFixed(2) || ''}/${equipmentInfo?.duration}`;
    }
    if (
      isGateway &&
      app.processingType === ProcessingTypes.eCommerce &&
      !Boolean(isVarSheetOther) &&
      !Boolean(isOtherNotListed)
    ) {
      equipmentNameQuestion = 'Equipment Type';
      if (equipmentInfo?.price || equipmentInfo?.price === 0) {
        equipmentPrice = `$${equipmentInfo?.price?.toFixed(2) || ''}`;
      }
      equipmentPlacementType = 'Gateway Setup by Processor';
      if (app.rateSet?.gatewaySetupFee) {
        equipmentSetupFee = `$${(+app.rateSet?.gatewaySetupFee).toFixed(2) || ''}`;
      }
      if (app.rateSet?.gatewayMonthlyFee) {
        equipmentMonthlyFee = `$${(+app.rateSet?.gatewayMonthlyFee).toFixed(2) || ''}`;
      }
      if (app.rateSet?.gatewayTransactionFee) {
        equipmentTransFee = `$${(+app.rateSet?.gatewayTransactionFee).toFixed(2) || ''}`;
      }
      equipmentBillingMethod = equipmentInfo?.duration ?? 'Monthly';
    }
    if (
      isGateway &&
      app.processingType === ProcessingTypes.eCommerce &&
      Boolean(isOtherNotListed)
    ) {
      equipmentName = undefined;
      equipmentPlacementType = 'VAR Sheet Only';
      const zeroDollars = `$0.00`;
      equipmentPrice = zeroDollars;
      equipmentSetupFee = zeroDollars;
      equipmentMonthlyFee = zeroDollars;
      equipmentTransFee = zeroDollars;
      hideSoftwareName = false;
    }
    return {
      sectionAnswers: [
        {
          isAvailable: true,
          header: 'Program',
          questionAnswers: [
            getQuestionAnswer('Name', programName, !!programName),
            getQuestionAnswer('Price', featuredInfo, !!featuredInfo),
          ],
        },
        {
          isAvailable: true,
          header: app.processingType === ProcessingTypes.eCommerce ? 'Gateway' : 'Equipment',
          questionAnswers: [
            getQuestionAnswer(equipmentNameQuestion, equipmentName, !!equipmentName),
            getQuestionAnswer('Placement Type', equipmentPlacementType, !!equipmentPlacementType),
            getQuestionAnswer('Setup Fee', equipmentSetupFee, !!equipmentSetupFee),
            getQuestionAnswer('Monthly Fee', equipmentMonthlyFee, !!equipmentMonthlyFee),
            getQuestionAnswer('Trans Fee', equipmentTransFee, !!equipmentTransFee),
            getQuestionAnswer('Price', equipmentPrice, !!equipmentPrice),
            getQuestionAnswer('Billing Method', equipmentBillingMethod, !!equipmentBillingMethod),
            getQuestionAnswer('Software/Gateway Name', softwareName, !hideSoftwareName),
          ],
        },
        getEquipmentShipping(isGateway),
      ],
    };
  };

  const getEquipmentData = (
    isAdditionalEquipment: boolean,
    equipment: ApplicationEquipment | undefined,
    applicationType: ApplicationType | undefined
  ): SectionAnswers => {
    const isEquipmentIsVarSheet = equipment?.isVarSheet === true;
    const equipmentAdditionalFields = !equipment ? [] : equipment?.additionalFields ?? [];
    const equipmentAdditionalFieldsAnswers: QuestionAnswer[] = [];
    equipmentAdditionalFields
      .filter(x => isEquipmentIsVarSheet && x.type === 'text')
      .map(x => {
        const answer = getAvailableQuestionAnswer(x.label, get(app, x.name));
        equipmentAdditionalFieldsAnswers.push(answer);
        return x;
      });
    const isGateway = equipment?.isGateway;
    const equipmentPlacementType = equipment?.placementType;
    const equipmentPlacementValue = equipmentPlacementType
      ? PlacementsValues[equipmentPlacementType as PlacementType]
      : undefined;

    return {
      isAvailable: true,
      header: isAdditionalEquipment
        ? `Additional equipment: ${equipment?.description ?? ''}`
        : 'Equipment',
      questionAnswers: [
        getAvailableQuestionAnswer('Equipment/Gateway', equipment?.name),
        ...equipmentAdditionalFieldsAnswers,
        getQuestionAnswer('Placement Type', equipmentPlacementValue, !!isGateway),
        getQuestionAnswer('Equipment Serial Number', equipment?.serialNumber, !isGateway),
        getQuestionAnswer(
          'Setup Fee',
          equipment?.virtualSetupFee,
          equipmentPlacementType === PlacementType.gatewaySetupByProcessor && isGateway === true
        ),
        getQuestionAnswer(
          'Monthly Fee',
          equipment?.virtualMonthlyFee,
          equipmentPlacementType === PlacementType.gatewaySetupByProcessor && isGateway === true
        ),
        getQuestionAnswer(
          'Trans Fee',
          equipment?.virtualTransactionFee,
          equipmentPlacementType === PlacementType.gatewaySetupByProcessor && isGateway === true
        ),

        getQuestionAnswer('Price', equipment?.price, isGateway === false),
        getQuestionAnswer('Billing Method', equipment?.duration, isGateway === false),
        getQuestionAnswer('Payment Type', equipment?.paymentType, isGateway === false),
        getQuestionAnswer('Connection Type', equipment?.connectionType, isGateway === false),
        getQuestionAnswer('Contactless', getYesNo(equipment?.contactless), isGateway === false),
        getQuestionAnswer('File Type', equipment?.fileType, isGateway === false),
        getQuestionAnswer(
          'Tip Mode',
          equipment?.tipMode,
          isGateway === false && equipment?.fileType !== EquipmentFileType.retail
        ),
        getQuestionAnswer(
          `${
            applicationType === ApplicationType.surcharge ||
            applicationType === ApplicationType.dualPricing
              ? 'Card Holder as Surcharge'
              : 'Cash Discount'
          }`,
          getYesNo(equipment?.cashDiscount),
          isGateway === false
        ),
        getQuestionAnswer(
          `${
            applicationType === ApplicationType.surcharge ||
            applicationType === ApplicationType.dualPricing
              ? 'Surcharge Percent Billed to Card Holder'
              : 'Cash Discount Percent Billed to Card Holder'
          }`,
          equipment?.cashDiscountPercent,
          isGateway === false &&
            (applicationType === ApplicationType.cashDiscount ||
              applicationType === ApplicationType.surcharge ||
              applicationType === ApplicationType.dualPricing ||
              app.equipment?.cashDiscount !== false)
        ),
        getQuestionAnswer('Auto Batch', getYesNo(equipment?.autoBatch), isGateway === false),
        getQuestionAnswer(
          'Batch Time',
          equipment?.autoBatchTime,
          isGateway === false && app.equipment?.autoBatch !== false
        ),
        getQuestionAnswer('Accessories', equipment?.accessories?.join(';'), isGateway === false),
      ],
    };
  };

  const getAdditionalEquipmentData = (
    applicationType: ApplicationType | undefined
  ): SectionAnswers[] => {
    const result: SectionAnswers[] = [];
    if (!app.equipmentAdditional) {
      return result;
    }

    app.equipmentAdditional.map(x => {
      const sectionAnswers = getEquipmentData(true, x, applicationType);
      result.push(sectionAnswers);
      return x;
    });
    return result;
  };

  const getSelectYourProgramStepAnswersForAgentOrErpUser = (): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    const applicationType = app.rateSet?.applicationType;
    const isAutoFillMidAndNonQual =
      !!applicationType &&
      [
        ApplicationType.cashDiscount,
        ApplicationType.surcharge,
        ApplicationType.dualPricing,
        ApplicationType.flatRate,
        ApplicationType.interchange,
      ].includes(applicationType);
    const isGateway = app.equipment?.isGateway;
    return {
      sectionAnswers: [
        {
          isAvailable: true,
          header: 'Pricing & Equipment',
          questionAnswers: [
            getAvailableQuestionAnswer('Application Type', app.rateSet?.applicationType),
            getAvailableQuestionAnswer('Discount Duration', app.rateSet?.discountDuration),
            getAvailableQuestionAnswer('Mail Statement', getYesNo(app.rateSet?.mailStatement)),
            getQuestionAnswer(
              'Statement Type',
              app.rateSet?.statementType,
              app.rateSet?.applicationType === ApplicationType.interchange
            ),
          ],
        },
        {
          isAvailable: isCashDiscountProg || isSurchargeDualPricing,
          header: 'Visa, Mastercard, Discover and AMEX',
          questionAnswers: [
            getAvailableQuestionAnswer(
              isSurchargeDualPricing ? 'Credit/Signature Debit Rate' : 'Credit/Debit Rate',
              app.rateSet?.qualifiedDiscountRateVisaMastercardDiscover,
              true,
              app.rateSet?.qualifiedDiscountRateVisaMastercardDiscover &&
                `Adjusted Discount Rate Billed to Merchant ${getAdjustedDiscountRateBilledToMerchant(
                  app.rateSet?.qualifiedDiscountRateVisaMastercardDiscover
                )}`
            ),
            getAvailableQuestionAnswer(
              'Transaction Fee',
              app.rateSet?.qualifiedTransactionFeeVisaMastercardDiscover
            ),
          ],
        },
        {
          isAvailable: !isCashDiscountProg && !isSurchargeDualPricing,
          header: `${PRICING_VISA_MASTER}${isDebit ? ' and Debit' : ''}`,
          questionAnswers: [
            getAvailableQuestionAnswer(
              'Qualified Rate',
              app.rateSet?.qualifiedDiscountRateVisaMastercardDiscover
            ),
            getQuestionAnswer(
              'Mid-Qualified Rate',
              app.rateSet?.midQualifiedDiscountRateVisaMastercardDiscover,
              !isAutoFillMidAndNonQual &&
                applicationType !== ApplicationType.cnpVCp &&
                applicationType !== ApplicationType.err
            ),
            getQuestionAnswer(
              isErrProg ? 'Non-Qualified Surcharge' : 'Non-Qualified Rate',
              app.rateSet?.nonQualifiedDiscountRateVisaMastercardDiscover,
              !isAutoFillMidAndNonQual
            ),
            getAvailableQuestionAnswer(
              'Qualified Transaction Fee',
              app.rateSet?.qualifiedTransactionFeeVisaMastercardDiscover
            ),
            getQuestionAnswer(
              'Mid-Qualified Transaction Fee',
              app.rateSet?.midQualifiedTransactionFeeVisaMastercardDiscover,
              !isAutoFillMidAndNonQual &&
                applicationType !== ApplicationType.cnpVCp &&
                applicationType !== ApplicationType.err
            ),
            getQuestionAnswer(
              'Non-Qualified Transaction Fee',
              app.rateSet?.nonQualifiedTransactionFeeVisaMastercardDiscover,
              !isAutoFillMidAndNonQual && applicationType !== ApplicationType.err
            ),
          ],
        },
        {
          isAvailable: !isCashDiscountProg && !isSurchargeDualPricing,
          header: 'American Express',
          questionAnswers: [
            getAvailableQuestionAnswer('Qualified Rate', app.rateSet?.qualifiedDiscountRateAmex),
            getQuestionAnswer(
              'Mid-Qualified Rate',
              app.rateSet?.midQualifiedDiscountRateAmex,
              !isAutoFillMidAndNonQual &&
                applicationType !== ApplicationType.cnpVCp &&
                applicationType !== ApplicationType.err
            ),
            getQuestionAnswer(
              isErrProg ? 'Non-Qualified Surcharge' : 'Non-Qualified Rate',
              app.rateSet?.nonQualifiedDiscountRateAmex,
              !isAutoFillMidAndNonQual
            ),
            getAvailableQuestionAnswer(
              'Qualified Transaction Fee',
              app.rateSet?.qualifiedTransactionFeeAmex
            ),
            getQuestionAnswer(
              'Mid-Qualified Transaction Fee',
              app.rateSet?.midQualifiedTransactionFeeAmex,
              !isAutoFillMidAndNonQual &&
                applicationType !== ApplicationType.cnpVCp &&
                applicationType !== ApplicationType.err
            ),
            getQuestionAnswer(
              'Non-Qualified Transaction Fee',
              app.rateSet?.nonQualifiedTransactionFeeAmex,
              !isAutoFillMidAndNonQual && applicationType !== ApplicationType.err
            ),
          ],
        },
        {
          isAvailable:
            !isCashDiscountProg &&
            (!isCnpProg || app.processingType === ProcessingTypes.cardPresent),
          header: 'Debit',
          questionAnswers: [
            getAvailableQuestionAnswer(
              'Signature Debit Non-Pin Based Rate',
              app.rateSet?.qualifiedDebitNonPINRate,
              !isCnpProg && !isSurchargeDualPricing
            ),
            getAvailableQuestionAnswer(
              'Signature Debit Non-Pin Based Transaction Fee',
              app.rateSet?.qualifiedDebitNonPINFee,
              !isCnpProg && !isSurchargeDualPricing
            ),
            getAvailableQuestionAnswer(
              'Debit Pin Based Rate',
              app.rateSet?.qualifiedDebitPINRate,
              app.processingType === ProcessingTypes.cardPresent
            ),
            getAvailableQuestionAnswer(
              'Debit Pin Based Transaction Fee',
              app.rateSet?.qualifiedDebitPINFee,
              app.processingType === ProcessingTypes.cardPresent
            ),
          ],
        },
        {
          isAvailable: true,
          header: 'EBT',
          questionAnswers: [
            getAvailableQuestionAnswer('Discount Rate', app.rateSet?.ebtDiscountRate),
            getAvailableQuestionAnswer('Transaction Fee', app.rateSet?.ebtTransactionFee),
          ],
        },
        {
          isAvailable: true,
          header: 'Other Volume & Other Item Fee (All Card Types)',
          questionAnswers: [
            getAvailableQuestionAnswer('Discount Rate', app.rateSet?.otherVolumeRate),
            getAvailableQuestionAnswer('Transaction Fee', app.rateSet?.otherItemFee),
          ],
        },
        {
          isAvailable: true,
          header: 'Miscellaneous Fees',
          questionAnswers: [
            getAvailableQuestionAnswer('Account on File Fee', app.rateSet?.accountOnFileFee),
            getAvailableQuestionAnswer('Chargeback Fee', app.rateSet?.chargebackFee),
            getAvailableQuestionAnswer('Retrieval Fee', app.rateSet?.retrievalFee),
            getAvailableQuestionAnswer('Monthly Minimum Fee', app.rateSet?.monthlyMinimumFee),
            getAvailableQuestionAnswer('AVS Fee', app.rateSet?.avsFee),
            getAvailableQuestionAnswer('Batch Fee', app.rateSet?.batchFee),
            getAvailableQuestionAnswer('Debit Access Fee', app.rateSet?.debitAccessFee),
            getAvailableQuestionAnswer(
              'Additional Service Fees',
              app.rateSet?.additionalServicesFee
            ),
            getAvailableQuestionAnswer(
              'PCI Fee',
              app.rateSet?.pciFee ? PCIFeeTypes[app.rateSet?.pciFee as PCIFee] : '-'
            ),
            getQuestionAnswer(
              'PCI Fee Value',
              app.rateSet?.pciFeeValue,
              app.rateSet?.pciFee !== undefined && app.rateSet?.pciFee !== PCIFee.waived
            ),
            getAvailableQuestionAnswer('Early Deconversion Fee', app.rateSet?.earlyDeconversionFee),
            getAvailableQuestionAnswer('Annual Fee', app.rateSet?.annualFee),
            getAvailableQuestionAnswer('Regulatory Fee', app.rateSet?.regulatoryFee),
          ],
        },
        getEquipmentData(false, app.equipment, applicationType),
        ...getAdditionalEquipmentData(applicationType),
        getEquipmentShipping(isGateway),
        {
          isAvailable: true,
          header: 'Application Ownership',
          questionAnswers: [
            getAvailableQuestionAnswer(
              'Agent',
              app.agent ? `${app.agent.firstName} ${app.agent.lastName}` : 'Unassigned'
            ),
          ],
        },
      ],
    };
  };

  return {
    getSelectYourProgramStepAnswers,
    getSelectYourProgramStepAnswersForAgentOrErpUser,
  };
};
